<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <validation-observer v-slot="{ handleSubmit }">
      <v-form
        ref="form"
        lazy-validation
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <v-card>
          <v-card-title primary-title />
          <v-card-text>
            <!--username and password-->
            <v-row class="mt-10">
              <v-col cols="6">
                <v-row>
                  <v-col
                    class="text-button align-self-center pt-0 mb-9"
                    cols="4"
                  >
                    <div>
                      <span class="font-weight-bold">{{
                        $t('messages.user_id')
                      }}</span>
                      <span class="red--text"> *</span>
                    </div>
                  </v-col>
                  <v-col
                    class="text-button align-self-center pb-0 pt-0"
                    cols="7"
                  >
                    <div>
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <v-text-field
                          id="username"
                          v-model="username"
                          name="username"
                          outlined
                          readonly
                          :error-messages="errors[0]"
                        />
                      </validation-provider>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row v-if="userType == 1">
                  <v-col
                    class="text-button align-self-center pt-0"
                    cols="3"
                  >
                    <div>
                      <span class="font-weight-bold">{{
                        $t("messages.password")
                      }}</span>
                    </div>
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      color="primary"
                      @click="resetPass"
                    >
                      {{ $t("button.resetPassword") }}
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="3"
                    class="text-button align-self-center pb-0 pt-0"
                  >
                    <v-text-field
                      ref="newPass"
                      v-model="oneTimePass"
                      name="oneTimePass"
                      :type="showPass ?'text':'password'"
                      :append-icon="showPass ?'mdi-eye':'mdi-eye-off'"
                      outlined
                      readonly
                      @click:append="showPass=!showPass"
                      @focus="$event.target.select()"
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      v-clipboard:copy="oneTimePass"
                      v-clipboard:success="onSuccess"
                      v-clipboard:error="onError"
                    >
                      {{ alertCopy.text }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="mt-12">
              <v-col
                v-for="(value, index) in fields"
                :key="index"
                class="align"
                cols="6"
              >
                <v-row>
                  <v-col
                    v-if="value.type !== 'spacer'"
                    class="text-button align-self-center pt-0 mb-9 "
                    cols="4"
                  >
                    <div>
                      <span class="font-weight-bold">{{
                        $t('messages.' + value.text)
                      }}</span>
                      <span class="red--text">
                        {{
                          value.validate
                            ? value.validate.includes('required')
                              ? '*'
                              : ''
                            : ''
                        }}</span>
                    </div>
                  </v-col>
                  <v-col
                    class="text-button align-self-center pb-0 pt-0"
                    cols="7"
                  >
                    <v-textarea
                      v-if="value.type === 'textarea'"
                      v-model="value.value"
                      rows="1"
                      row-height="15"
                      :placeholder="$t(value.placeholder)"
                      :clearable="userType !== 2"
                      :readonly="userType === 2"
                      outlined
                    />
                    <validation-provider
                      v-else-if="value.type === 'date-text'"
                      v-slot="{ errors }"
                      :name="value.key"
                      :rules="value.validate ? value.validate : ''"
                    >
                      <div v-if="'menu' in value">
                        <v-row>
                          <v-col cols="10">
                            <v-text-field
                              v-model="value.value"
                              :name="value.key"
                              clearable
                              outlined
                              autocomplete="off"
                              :error-messages="errors[0]"
                              :placeholder="$t(value.placeholder)"
                              @keydown="checkDate"
                            >
                              <template v-slot:append />
                            </v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-menu
                              :ref="'menu' + value.key"
                              v-model="value.menu"
                              :close-on-content-click="false"
                              :return-value.sync="value.value"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  class="mt-4 mr-3"
                                  v-bind="attrs"
                                  left
                                  v-on="on"
                                >
                                  mdi-calendar
                                </v-icon>
                              </template>
                              <v-date-picker
                                v-model="value.value"
                                locale="ja-jn"
                                no-title
                                scrollable
                                :max="value.max_date ? value.max_date : ''"
                              >
                                <v-spacer />
                                <v-btn
                                  text
                                  color="primary"
                                  @click="value.menu = false"
                                >
                                  {{ $t('messages.cancel') }}
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="
                                    $refs['menu' + value.key][0].save(
                                      value.value
                                    )
                                  "
                                >
                                  {{ $t('messages.ok') }}
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </div>
                      <div v-else>
                        <v-text-field
                          v-model="value.value"
                          :name="value.key"
                          :clearable="userType !== 2"
                          :readonly="userType === 2"
                          outlined
                          :error-messages="errors[0]"
                          :placeholder="$t(value.placeholder)"
                          @keydown="checkDate"
                        />
                      </div>
                    </validation-provider>
                    <validation-provider
                      v-else-if="value.type === 'select'"
                      v-slot="{ errors }"
                      :name="value.key"
                      :rules="value.validate ? value.validate : ''"
                    >
                      <v-autocomplete
                        v-if="value.key === 'inputCheckEmployee'"
                        v-model="value.value"
                        :name="value.key"
                        :items="employeeList"
                        :error-messages="errors[0]"
                        :item-text="item => item.agencyId + '_' + item.name"
                        :placeholder="$t(value.placeholder)"
                        item-value="value"
                        :clearable="userType !== 2"
                        :readonly="userType === 2"
                        outlined
                      />
                    </validation-provider>
                    <validation-provider
                      v-else-if="value.type === 'text'"
                      v-slot="{ errors }"
                      :name="value.key"
                      :rules="value.validate ? value.validate : ''"
                    >
                      <v-text-field
                        v-model="value.value"
                        :placeholder="$t(value.placeholder)"
                        :name="value.key"
                        :disabled="value.disabled"
                        outlined
                        :error-messages="errors[0]"
                        :clearable="userType !== 2"
                        :readonly="userType === 2"
                      />
                    </validation-provider>
                    <validation-provider
                      v-else-if="value.type === 'checkbox'"
                      v-slot="{ errors }"
                      :name="value.key"
                    >
                      <v-checkbox
                        v-model="value.value"
                        :readonly="userType === 2"
                        :name="value.key"
                        :error-messages="errors[0]"
                        color="#0F56B3"
                        style="margin-top: -23px; width: 5%"
                      />
                    </validation-provider>
                    <validation-provider
                      v-else-if="value.type === 'upload'"
                      v-slot="{ errors }"
                      :name="value.key"
                    >
                      <v-file-input
                        prepend-icon="mdi-camera"
                        :name="value.key"
                        accept="image/*"
                        outlined
                        :error-messages="errors[0]"
                        :clearable="userType !== 2"
                        :disabled="userType === 2"
                        @change="selectImage"
                      />
                      <span>{{ fileNumber }} ファイル</span>
                      <v-btn
                        color="primary"
                        @click="previewImage"
                      >
                        {{ $t('button.preview') }}
                      </v-btn>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!--others-->

            <!--button-->
            <v-row align="center" />
          </v-card-text>
          <v-card-actions />
        </v-card>
        <v-row class="d-flex justify-end mt-3">
          <v-col
            cols="2"
            class="d-flex justify-space-between"
          >
            <v-btn
              v-if="userType !== 2"
              large
              block
              color="info"
              type="submit"
            >
              <span class="text-h5 pr-3 pl-3">
                {{ $t('messages.save') }}</span>
            </v-btn>
          </v-col>
          <v-col
            cols="2"
            class="d-flex justify-space-between"
          >
            <v-btn
              v-if="userType !== 2"
              large
              block
              color="info"
              @click="openDialogDelete"
            >
              <span class="text-h5 pr-3 pl-3">
                {{ $t('messages.delete') }}</span>
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn
              large
              block
              color="info"
            >
              <span
                class="text-h5 pr-3 pl-3"
                @click="$router.push('/customer-list/')"
              >
                {{ $t('messages.close') }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="font-weight-bold text-h4">
          <v-row class="d-flex align-center justify-center">
            <v-col cols="1">
              <v-icon
                large
                color="blue darken-2"
              >
                mdi-delete-outline
              </v-icon>
            </v-col>
            <v-col cols="8">
              この操作は元に戻りません。
              本当に削除してよろしいですか？
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions>
          <v-spacer />

          <v-btn
            class="text-h5"
            dark
            color="#E43E08"
            @click="deleteItem"
          >
            {{ $t('messages.delete') }}
          </v-btn>
          <v-btn
            class="text-h5"
            @click="dialogDelete = false"
          >
            {{ $t('messages.cancel') }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="preview"
      max-width="500px"
      max-height="500px"
    >
      <v-img
        max-height="500"
        max-width="500"
        contain
        class="grey darken-4"
        :src="imagePreviewSrc"
      />
    </v-dialog>
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
  import { required, email, min } from 'vee-validate/dist/rules'
  import moment from 'moment'
  import _ from 'lodash'
  import { api } from '@/config/index'
  import FormData from 'form-data'

  export default {
    name: 'CustomerDetail',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data () {
      return {
        rePasswordCheck: '',
        username: '',
        snackbarMessage: '',
        snackbar: false,
        dialogDelete: false,
        snackbarCloseInerval: null,
        fields: [
          {
            text: 'factoryName',
            key: 'name',
            value: '',
            type: 'text',
            validate: 'required',
          },
          {
            text: 'contactPhone',
            key: 'contactPhone',
            value: '',
            type: 'text',
            validate: 'required|phone',
          },
          {
            text: 'managerIdCustomer',
            key: 'code',
            value: '',
            type: 'text',
            validate: 'required',
            disabled: true,
          },
          {
            text: 'email',
            key: 'email',
            value: '',
            type: 'text',
            validate: 'required|email',
            placeholder: 'placeholder.email',
          },
          {
            text: 'deadlineAfterTransferMail',
            key: 'invoiceSendDeadline',
            value: '',
            type: 'text',
            validate: 'required',
          },
          {
            text: 'responsiblePerson',
            key: 'inChargedEmployee',
            value: '',
            type: 'text',
            validate: 'required',
          },
          {
            text: 'sendAddress',
            key: 'sendAddress',
            value: '',
            type: 'text',
            validate: 'required',
          },
          {
            text: 'personInput',
            key: 'inputEmployee',
            value: '',
            type: 'text',
            validate: 'required',
          },
          {
            text: 'contactAddress',
            key: 'contactAddress',
            value: '',
            type: 'text',
          },
          {
            text: 'inputCheckEmployee',
            key: 'inputCheckEmployee',
            value: '',
            type: 'select',
            validate: 'required',
          },
          {
            text: 'payday',
            key: 'payday',
            value: '',
            type: 'text',
            validate: 'required',
          },
          {
            text: 'personTransfer',
            key: 'paymentCheckEmployee',
            value: '',
            type: 'text',
            validate: 'required',
          },
          {
            key: 'note',
            text: 'note',
            value: '',
            type: 'textarea',
            class: 'red--text',
          },
          {
            key: 'weekendHoliday',
            text: 'holidays',
            value: '',
            type: 'text',
          },
          {
            key: 'isFixed',
            text: 'fixed',
            value: false,
            type: 'checkbox',
          },
          {
            key: 'isOtNightShift',
            text: 'isOtNightShift',
            value: false,
            type: 'checkbox',
          },
          {
            key: 'avatar',
            text: 'uploadImage',
            value: '',
            type: 'upload',
            validate: 'required',
          },
        ],
        userType: 0,
        alertCopy: {
          text: this.$t('button.copy'),
        },
        showPass: false,
        oneTimePass: '',
        avatar: '',
        preview: false,
        fileNumber: 0,
        imagePreviewSrc: null,
      }
    },
    computed: {
      ...get('customer', ['message', 'status', 'error', 'customerDetail', 'newPassword', 'resetPassword']),
      employeeList: get('employee@list.data.employees'),
    },
    watch: {
      error (value) {
        if (value) {
          this.snackbarMessage = this.$t(value)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      message (value) {
        if (value === 'success') {
          this.showSnackBar('messages.success')
          this.$router.push({ path: '/customer-list', query: { success: true } })
        } else if (value === 'successChangePass') {
          this.showSnackBar('messages.successChangePass')
        } else if (value === 'updateSuccess') {
          this.$router.push({ path: '/customer-list', query: { updateSuccess: true } })
        }
      },
      customerDetail (value) {
        this.username = _.get(value, 'username', '')

        for (const [key, val] of Object.entries(value)) {
          const field = this.fields.find(o => o.key === key)
          if (field) {
            field.value = val
            if (field.key === 'avatar') {
              this.fileNumber = field.value.length > 0 ? 1 : 0
              this.imagePreviewSrc = api.baseUrl + '/customer' + field.value
            }
          }
        }
        this.oneTimePass = this.newPassword
      },
      resetPassword (value) {
        if (value.success === 1) {
          this.oneTimePass = value.data.newPassword
        }
      },
    },
    created () {
      this.userType = JSON.parse(localStorage.getItem('user')).detail.type

      if (this.userType !== 1 && this.userType !== 2) {
        this.$router.push('/')
      }
      this.$store.dispatch('customer/getCustomer', {
        id: this.$route.params.id,
      })
    },
    mounted () {
      // Define the rule globally
      extend('required', {
        ...required,
        message: this.$t('validator.required'),
      })

      extend('email', {
        ...email,
        message: this.$t('validator.email_invalid'),
      })

      extend('number', {
        validate (value) {
          return /^\+?(0|[1-9]\d*)$/.test(value)
        },
        message: this.$t('validator.must_be_number'),
      })
      extend('min', {
        ...min,
        message: this.$t('validator.password_invalid'),
      })
      extend('date', {
        validate (value) {
          return moment(value, 'YYYY-mm-DD').format('YYYY-mm-DD') === value
        },
        message: this.$t('validator.invalid_date'),
      })
      extend('password', {
        params: ['target'],
        validate (value, target) {
          if (typeof target === 'object') return value === target[0]
          else if (typeof target === 'string' || typeof target === 'number') {
            return value === target
          }
        },
        message: this.$t('validator.confirm_password_invalid'),
      })
      extend('passwordRegex', {
        validate (value) {
          return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(value)
        },
        message: '英大文字・英小文字・数字を組み合わせて8文字以上で入力してください',
      })
      extend('phone', {
        validate (value) {
          return /^[+]?[(]?[0-9]{4}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,5}$/.test(value)
        },
        message: this.$t('数字のみ入力してください。'),
      })
      this.$store.dispatch('employee/getEmployees')
    },
    methods: {
      selectImage (value) {
        if (value !== undefined) {
          this.avatar = value
        }
      },
      previewImage () {
        this.preview = true
      },
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      onSuccess () {
        this.alertCopy.text = this.$t('messages.copied')
      },
      onError () {
        this.alertCopy.text = this.$t('messages.uncopy')
      },
      deleteItem () {
        const payload = {
          userId: this.$route.params.id,
        }
        this.$store.dispatch('customer/delete', payload)
      },
      openDialogDelete () {
        this.dialogDelete = true
      },
      onSubmit () {
        if (this.rePasswordCheck) return
        const payload = {
          id: this.$route.params.id,
          name: this.fields.find(o => o.key === 'name').value,
          email: this.fields.find(o => o.key === 'email').value,
          contactPhone: this.fields.find(o => o.key === 'contactPhone').value,
          contactAddress: this.fields.find(o => o.key === 'contactAddress').value,
          payday: this.fields.find(o => o.key === 'payday').value,
          sendAddress: this.fields.find(o => o.key === 'sendAddress').value,
          invoiceSendDeadline: this.fields.find(o => o.key === 'invoiceSendDeadline').value,
          inputCheckEmployee: this.fields.find(o => o.key === 'inputCheckEmployee').value,
          inputEmployee: this.fields.find(o => o.key === 'inputEmployee').value,
          inChargedEmployee: this.fields.find(o => o.key === 'inChargedEmployee').value,
          paymentCheckEmployee: this.fields.find(o => o.key === 'paymentCheckEmployee').value,
          note: this.fields.find(o => o.key === 'note').value,
          weekendHoliday: this.fields.find(o => o.key === 'weekendHoliday').value === null ? false : this.fields.find(o => o.key === 'weekendHoliday').value,
          isFixed: this.fields.find(o => o.key === 'isFixed').value === null ? false : this.fields.find(o => o.key === 'isFixed').value,
          isOtNightShift: this.fields.find(o => o.key === 'isOtNightShift').value === null ? false : this.fields.find(o => o.key === 'isOtNightShift').value,
          avatar: this.avatar,
        }
        if (payload.avatar.length === 0) delete payload.avatar
        var formData = new FormData()
        Object.entries(payload).forEach(([key, value]) => {
          formData.append(key, value)
        })
        this.$store.dispatch('customer/update', formData)
      },
      hasErrors (errors) {
        return errors.length > 0
      },
      resetPass () {
        const payload = {
          id: this.customerDetail.id,
        }
        this.$store.dispatch('customer/resetPassword', payload)
      },
    },
  }
</script>
